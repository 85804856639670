import RichText from '@connected/RichText';
import SEO from '@connected/SEO';
import ArticleBanner, { thumbnail } from '@presentation/ArticleBanner';
import { graphql } from 'gatsby';
import React from 'react';
import { ContentModels } from 'schema/ContentModels';

interface EventPageProps {
  data: {
    eventPage: ContentModels.ContentfulEvent;
    eventList: ContentModels.ContentfulEventList;
    site: {
      siteMetadata: {
        origin: string;
      };
    };
  };
}

const EventPage: React.FC<EventPageProps> = ({ data }) => {
  return (
    <>
      <SEO data={data.eventPage.metadata} url={data.eventPage.url} />
      <ArticleBanner
        backUrl={data.eventList.url}
        date={new Date(data.eventPage.date ?? '')}
        origin={data.site.siteMetadata.origin}
        shareData={{
          title: data.eventPage.title,
          url: data.eventPage.url,
        }}
        subtitle="Events"
        thumbnail={thumbnail(data.eventPage.thumbnail?.[0]?.public_id)}
        title={data.eventPage.title}
      />
      {data.eventPage.body && <RichText data={data.eventPage.body} />}
    </>
  );
};

export default EventPage;

export const query = graphql`
  query EventPageQuery($slug: String) {
    eventPage: contentfulEvent(slug: { eq: $slug }) {
      contentful_id
      metadata {
        ...ContentfulMetadataFragment
      }
      body {
        ...RichTextFragment
      }
      date
      thumbnail
      title
      url
    }
    eventList: contentfulEventList {
      url
    }
    site {
      siteMetadata {
        origin
      }
    }
  }
`;
